<template>

<div class="container">

    <div class="row">
        <h2 class="h2">Documentos SIG</h2>
    </div>
    <br>
    <div class="row">
        <div class="col-sm">
            <a href="/pdf_sunrisecargo/F-C-02_Condiciones_Contractuales-Agen_de_carga.pdf" target="_blank"><h4 class="h4 py-2" style="color: gray;"> <img src="/imagenes/pdf2.png" class="rounded" alt="..." style="width: 72px; margin-right: 20px;"> Condiciones contractuales</h4></a>
            <a href="/pdf_sunrisecargo/F-C-01_Proceso_de_Reclamos.pdf" target="_blank"><h4 class="h4 py-2" style="color: gray;"> <img src="/imagenes/pdf2.png" class="rounded" alt="..." style="width: 72px; margin-right: 20px;" > Proceso de reclamos</h4></a>
            <a href="/pdf_sunrisecargo/P-SIG-02_Politica_proteccion_de_datos.pdf" target="_blank"><h4 class="h4 py-2" style="color: gray;"> <img src="/imagenes/pdf2.png" class="rounded" alt="..." style="width: 72px; margin-right: 20px;"> Políticas de protección de datos</h4></a>
            <a href="/pdf_sunrisecargo/F-SIG-21_Autoriza_tratamiento_de_datos.docx" target="_blank"><h4 class="h4 py-2" style="color: gray;"> <img src="/imagenes/pdf2.png" class="rounded" alt="..." style="width: 72px; margin-right: 20px;"> Autorización tratamiento de datos</h4></a>
        </div>

        <div class="col-sm">
            <a href="/pdf_sunrisecargo/CertificadoISO9001.pdf" target="_blank"><h4 class="h4 py-2" style="color: gray;"> <img src="/imagenes/pdf2.png" class="rounded" alt="..." style="width: 72px; margin-right: 20px;"> Certificación ISO 9001:2015 código certificado CO03/372</h4></a>
            <a href="/pdf_sunrisecargo/CertificadoISO28000.pdf" target="_blank"><h4 class="h4 py-2" style="color: gray;"> <img src="/imagenes/pdf2.png" class="rounded" alt="..." style="width: 72px; margin-right: 20px;"> Certificación ISO 28000</h4></a>
            <a href="/pdf_sunrisecargo/Politica_de_Seguridad_Vial.pdf" target="_blank"><h4 class="h4 py-2" style="color: gray;"> <img src="/imagenes/pdf2.png" class="rounded" alt="..." style="width: 72px; margin-right: 20px;"> Política de PESV</h4></a>
        </div>


    </div>
    <br>
</div>


</template>


<script>
  export default {
    data: () => ({
      //
    }),
  }
</script>

<style>

</style>
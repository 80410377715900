<template>
<ListaDescargas />
</template>


<script>
import ListaDescargas from '@/components/ListaDescargas.vue'

export default {
  name: 'Descargas',
  
  components: {
    ListaDescargas,
  },

  data: () => ({
    //
  }),

};
</script>